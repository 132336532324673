<template>
	<el-dialog title="" :visible.sync="dialogVisible" width="522px" custom-class="addModel" :center="true" :append-to-body="true" :show-close="false" :close-on-click-modal="false">
	 <div class="msg">
		 <div class="msg-title">答题学生</div>
		 <div class="wechatcode-desc">答题学生列表</div>
		 <div class="wechatcode-remark">请输入学生手机号，逗号分隔</div>
		
		 <textarea class="wechatcode-value" v-model="info.answerPhone" placeholder="请输入学生手机号，逗号分隔"></textarea>
	 </div>
	  <span slot="footer" class="dialog-footer flex-midden flex-center">
		  <div class="dialog-footer-one" @click="dialogVisible=false">取消</div>
		  <div class="dialog-footer-two" @click="submit">确认</div>
	  </span>
	</el-dialog>
</template>

<script>
	
	import {setUpAnswerUser} from "@/api/index"
	export default {
		data(){
			return {
				dialogVisible:false,
				info:{}
			}
		},
		methods:{
			init(info){
				this.info = {...info};
				this.dialogVisible = true;
			},
			submit(){
				setUpAnswerUser({
					id:this.info.id,
					answerPhone:this.info.answerPhone
				}).then(res => {
					if (res.code == 0) {
						this.dialogVisible = false;
						this.$emit("update");
					} else {
						this.$message.error(res.msg);
					}
				})
			}
		}
	}
</script>


<style scoped lang="scss">
	.msg{
		.msg-title{
			font-weight: bold;
			font-size: 16px;
			color: #16191E;
			line-height: 22px;
			text-align: center;
			padding: 0px 0px 17px;
		}
		.wechatcode-desc{
			width: 405px;
			font-weight: 500;
			font-size: 16px;
			color: #16191E;
			line-height: 22px;
			text-align: left;
			padding-bottom: 16px;
			margin: 0 auto;
		}
		.wechatcode-remark{
			width: 405px;
			font-size: 12px;
			color: #666;
			text-align: left;
			padding-bottom: 16px;
			margin: 0 auto;
		}
		.wechatcode-value{
			width: 405px;
			background: #F9F9F9;
			border-radius: 16px;
			padding: 18px 13px;
			font-weight: 400;
			font-size: 14px;
			color: #16191E;
			line-height: 20px;
			text-align: left;
			box-sizing: border-box;
			margin: 0 auto;
			height: 300px;
			display: block;
			border: 0px;
		}
	}
	.dialog-footer{
		.dialog-footer-one{
			width: 166px;
			height: 50px;
			border-radius: 4px;
			border: 1px solid #FF8D1F;
			font-weight: 400;
			font-size: 14px;
			color: #FF8D1F;
			line-height: 50px;
			text-align: center;
			margin: 32px 10px 0px;
			cursor: pointer;
		}
		.dialog-footer-two{
			width: 166px;
			height: 50px;
			background: #FF8D1F;
			border-radius: 6px;
			font-weight: 400;
			font-size: 16px;
			color: #FFFFFF;
			line-height: 50px;
			text-align: center;
			margin: 32px 10px 0px;
			cursor: pointer;
		}
	}
	::v-deep .addModel{
		border-radius: 10px !important;
	}
	::v-deep .el-dialog__title{
		height: 0px;
	}
	::v-deep .el-dialog--center .el-dialog__body{
		padding: 0px;
	}
	
</style>