<template>
	<div class="report">
		<img class="report-close" @click="tolast" src="@/assets/images/close.png"/>
		<div class="report-title">答题报告制作</div>
		
		<div class="content-table-search">
			<div class="content-table-search-value flex-start flex-center flex-wrap">
				<div class="content-table-search-value-item flex-start flex-center">
					<div class="content-table-search-value-title">知识点</div>
					<el-cascader v-model="knowled" :options="knowList" size="mini" style="width: 500px;" :props="{multiple: true,checkStrictly: true,value:'id',label:'name',children:'children'}" clearable></el-cascader>
					
				</div>
				<div class="content-table-search-value-button flex-start flex-center">
					<div @click="reast">重置</div>
					<div @click="tosearch()">筛选</div>
				</div>
			</div>
		</div>
		<div class="report-list flex-start flex-center flex-wrap">
			<div class="report-item" v-for="(item, index) in list" :key="index" v-if="item.level==1">
				<div class="report-item-title">{{item.name}}</div>
				<div class="report-item-info" :id="'main'+index"></div>
			</div>
		</div>
		<div class="report-list flex-start flex-center flex-wrap">
			<div class="report-item" v-for="(item, index) in list" :key="index" v-if="item.level==2">
				<div class="report-item-title">{{item.name}}</div>
				<div class="report-item-info" :id="'main'+index"></div>
			</div>
		</div>
		<div class="report-list flex-start flex-center flex-wrap">
			<div class="report-item" v-for="(item, index) in list" :key="index" v-if="item.level==3">
				<div class="report-item-title">{{item.name}}</div>
				<div class="report-item-info" :id="'main'+index"></div>
			</div>
		</div>
		
		<div style="height: 100px;"></div>
		<div class="bottom">
			<div @click="submit">确定生成</div>
		</div>
		
	</div>
</template>

<script>
	
	import {getKnowledgeList,getPaperAnswerMark,paperAnswerMarkReport,getAllByPaper} from "@/api/index"
	import * as echarts from 'echarts';
	export default {
		data(){
			return {
				id:0,
				list:[],
				answerId:0,
				knowList:[],
				knowled:[]
			}
		},
		mounted() {
			this.id = this.$route.query.id || 0;
			this.answerId = this.$route.query.answerId || 0;
			getAllByPaper({paperId:this.id}).then(res => {
				res.data.map(item=>{
					item.children.map(value=>{
						value.children.map(v=>{
							delete v.children;
						})
					})
				})
				this.knowList = res.data;
			})
		},
		methods:{
			tolast(){
				this.$router.go(-1); 
			},
			reast(){
				this.knowled = [];
				this.list = [];
			},
			tosearch(){
				let value = {
					knowledgeId1:[],
					knowledgeId2:[],
					knowledgeId3:[]
				};
				if(this.knowled.length == 0){
					return this.$message.error("请选择知识点");
				}
				this.knowled.map(item=>{
					if(item.length >= 1){
						value.knowledgeId1.push(item[0]);
					}
					if(item.length >= 2){
						value.knowledgeId2.push(item[1]);
					}
					if(item.length >= 3){
						value.knowledgeId3.push(item[2]);
					}
				})
				getPaperAnswerMark({
					paperId: this.id, 
					answerId: this.answerId, 
					knowledgeId1:value.knowledgeId1.join(","),
					knowledgeId2:value.knowledgeId2.join(","),
					knowledgeId3:value.knowledgeId3.join(","),
				}).then(res=>{
					if(res.code == 0){
						this.list = res.data;
						setTimeout(()=>{
							res.data.map((item,index)=>{
								var chartDom = document.getElementById('main'+index);
								var myChart = echarts.init(chartDom);
								var option;
								let x = [];
								let y = [];
								item.y.map(value=>{
									x.push(value.name);
									y.push(value.divide);
								})
								option = {
								  xAxis: {
								    type: 'category',
								    data: x
								  },
								  yAxis: {
								    type: 'value'
								  },
								  series: [
								    {
								      data: y,
								      type: 'bar',
								      barWidth: 20,
								      itemStyle: {
								        normal: {
								      	//这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
								      	barBorderRadius:[10, 10, 0, 0],
								      	color:function (params) {
								      	  // 根据params的
								      	  const colorsMap = [
								      	  '#FF8D1F',
								      	  '#66EEEC',
								      	  '#1F9DFF',
								      	  '#FF8E84','#FF8D1F',
								      	  '#66EEEC',
								      	  '#1F9DFF',
								      	  '#FF8E84','#FF8D1F',
								      	  '#66EEEC',
								      	  '#1F9DFF',
								      	  '#FF8E84'
								      	  ]
								      	  //返回对应的颜色
								      	  return colorsMap[params.dataIndex]
								      	}
								        }
								      },
								      label: {
								        show: true, // 显示标签
								        position: 'top', // 标签位置顶部
								        formatter: '{c}%' // 标签格式器，{c} 代表数据值
								      }
								    }
								  ]
								};
								
								option && myChart.setOption(option);
							})
						},500);
					}else{
						return this.$message.error(res.msg);
					}
				})
			},
			submit(){
				
				let value = {
					knowledgeId1:[],
					knowledgeId2:[],
					knowledgeId3:[]
				};
				if(this.knowled.length == 0){
					return this.$message.error("请选择知识点");
				}
				this.knowled.map(item=>{
					if(item.length >= 1){
						value.knowledgeId1.push(item[0]);
					}
					if(item.length >= 2){
						value.knowledgeId2.push(item[1]);
					}
					if(item.length >= 3){
						value.knowledgeId3.push(item[2]);
					}
				})
				paperAnswerMarkReport({
					paperId: this.id, 
					answerId: this.answerId, 
					knowledgeId1:value.knowledgeId1.join(","),
					knowledgeId2:value.knowledgeId2.join(","),
					knowledgeId3:value.knowledgeId3.join(","),
				}).then(res=>{
					if(res.code == 0){
						this.$message.success("提交成功");
						this.$router.go(-1);
					}else{
						return this.$message.error(res.msg);
					}
				})
			}
		}
	}
</script>


<style lang="scss" scoped>
	@import "../assets/report.scss";
	
	.content-table-search{
		.content-table-search-tab{
			padding: 0px 9px;
			box-sizing: border-box;
			div{
				width: 106px;
				height: 37px;
				background: #EFEFEF;
				border-radius: 8px;
				font-weight: 400;
				font-size: 14px;
				color: #16191E;
				line-height: 37px;
				text-align: center;
				margin-right: 14px;
				margin-bottom: 17px;
				cursor: pointer;
			}
			.active{
				background-color: #FF8D1F;
				color: #fff;
			}
		}
		.content-table-search-tab-two{
			padding: 0px 9px;
			box-sizing: border-box;
			div{
				width: 106px;
				height: 37px;
				background: #fff;
				border-radius: 8px;
				font-weight: 400;
				font-size: 14px;
				color: #16191E;
				line-height: 35px;
				border: 1px solid #F3F3F3;
				text-align: center;
				margin-right: 14px;
				margin-bottom: 17px;
				box-sizing: border-box;
				cursor: pointer;
			}
			.active{
				background: rgba(255,184,15,0.1);
				border: 1px solid rgba(255,184,15,0.1);
				color: #FF8D1F;
			}
		}
		.content-table-search-value{
			margin-top: 14px;
			.content-table-search-value-item{
				margin-right: 40px;
				margin-bottom: 16px;
				.content-table-search-value-title{
					font-weight: 400;
					font-size: 14px;
					color: #16191E;
					line-height: 20px;
					margin-right: 12px;
					width: 60px;
					text-align: right;
					flex: 0 0 60px;
				}
			}
		}
		.content-table-search-form{
			margin-top: 14px;
			margin-bottom: 21px;
			.content-table-search-form-item{
				margin-right: 50px;
				.content-table-search-form-title{
					font-weight: 400;
					font-size: 14px;
					color: #16191E;
					line-height: 20px;
					margin-right: 12px;
				}
				.content-table-search-form-check{
					margin: 0px 12px;
					cursor: pointer;
					img{
						width: 14px;
						height: 14px;
					}
					div{
						font-weight: 400;
						font-size: 12px;
						color: #3C4658;
						line-height: 17px;
						margin-left: 10px;
					}
				}
			}
		}
	}
	.content-table-search-value-button{
		margin-bottom: 16px;
		div{
			&:first-of-type{
				width: 75px;
				height: 32px;
				border-radius: 4px;
				border: 1px solid #FF8D1F;
				font-weight: 400;
				font-size: 14px;
				color: #FF8D1F;
				line-height: 32px;
				text-align: center;
				margin-right: 20px;
				cursor: pointer;
			}
			&:last-of-type{
				width: 75px;
				height: 32px;
				border-radius: 4px;
				border: 1px solid #FF8D1F;
				background-color: #FF8D1F;
				font-weight: 400;
				font-size: 14px;
				color: #fff;
				line-height: 32px;
				text-align: center;
				cursor: pointer;
				
			}
		}
	}
	
</style>