<template>
	<el-dialog title="" :visible.sync="dialogVisible" width="540px" custom-class="addModel" :center="true" :append-to-body="true" :show-close="false" :close-on-click-modal="false">
		<div class="num-title">自组试卷</div>
		<div class="info-content-left-dif flex-center flex-midden flex-wrap" v-if="dialogVisible">
			<div class="info-content-left-item">
				<div class="info-content-left-item-bottom">检索题目数量：{{info.paperNum}}</div>
			</div>
			<div class="info-content-left-item">
				<div class="info-content-left-item-bottom" :style="info.singleNum < info.inSingleNum?'color:red':''">选择题：{{info.singleNum}}/{{info.inSingleNum}}</div>
			</div>
			<div class="info-content-left-item">
				<div class="info-content-left-item-bottom" :style="info.writtenNum < info.inWrittenNum?'color:red':''">笔试题：{{info.writtenNum}}/{{info.inWrittenNum}}</div>
			</div>
		</div>
		<div class="info-content-left-dif flex-center flex-midden flex-wrap" v-if="info.questionDiffList.length > 0 && dialogVisible">
			<div v-for="item in info.questionDiffList" :key="'q'+item.name" class="info-content-left-item">
				<div class="info-content-left-item-bottom">{{item.name}}：{{item.ratio}}</div>
			</div>
		</div>
		<div class="info-content-left-info" v-if="info.questionKnowledge.length > 0 && dialogVisible">
			<template v-for="(item, index) in info.questionKnowledge">
				<div :key="index" class="info-content-left-info-left flex-midden">
					<div class="title-one">{{item.name}}</div>
					<div class="title-one" :style="item.ratio < item.proportion?'color:red':''">{{item.total}}道题({{item.ratio}}%<span v-if="item.proportion">/{{item.proportion}}%</span>)</div>
				</div>
				<template v-for="(value, key) in item.children">
					<div :key="key" class="info-content-left-info-left flex-midden">
						<div class="title-two">{{value.name}}</div>
						<div class="title-one" :style="value.ratio < value.proportion?'color:red':''">{{value.total}}道题({{value.ratio}}%<span v-if="value.proportion">/{{value.proportion}}%</span>)</div>
					</div>
					<template v-for="(v, k) in value.children">
						<div :key="k" class="info-content-left-info-left flex-midden">
							<div class="title-three">{{v.name}}</div>
							<div class="title-one" :style="v.ratio < v.proportion?'color:red':''">{{v.total}}道题({{v.ratio}}%<span v-if="v.proportion">/{{v.proportion}}%</span>)</div>
						</div>
					</template>
				</template>
			</template>
		</div>
		<span slot="footer" class="dialog-footer flex-midden flex-center">
			<el-button type="primary" class="dialog-footer-one" @click="dialogVisible=false">确认</el-button>
		</span>
	</el-dialog>
</template>

<script>
	
	import {getPaperDetails} from "@/api/index"
	export default {
		data(){
			return {
				dialogVisible:false,
				totalNum:0,
				info:{
					questionKnowledge:[],
					questionDiffList:[]
				}
			}
		},
		methods:{
			init(num,paper_id){
				getPaperDetails({
					paperId:paper_id
				}).then(res=>{
					if (res.code == 0) {
						this.info = res.data;
						this.dialogVisible = true;
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			getInfo(){
			}
		}
	}
</script>


<style scoped lang="scss">
	.info-content-left-dif{
		padding: 0px 0px 29px;
		.info-content-left-item{
			width: 25%;
			text-align: center;
		}
		.info-content-left-item-bottom{
			font-weight: 400;
			font-size: 14px;
			color: #777C85;
			line-height: 20px;
			text-align: center;
		}
	}
	.info-content-left-info{
		width: 517px;
		background: linear-gradient( 180deg, #FFFAF5 0%, #FFFFFF 100%);
		border-radius: 8px;
		padding: 19px 0px;
		// height: calc(100vh - 600px);
		// overflow-y: auto;
		margin: 0 auto;
		.info-content-left-info-left{
			text-align:left;
			margin: 0px 16px;
			div{
				margin-top: 4px;
				width: 40%;
			}
		}
		
		.title-one{
			font-weight: bold;
			font-size: 16px;
			color: #16191E;
			line-height: 22px;
			text-align: left;
		}
		.title-two{
			font-weight: bold;
			font-size: 14px;
			color: #16191E;
			line-height: 20px;
			text-align: left;
		}
		.title-three{
			font-weight: 400;
			font-size: 14px;
			color: #777C85;
			line-height: 20px;
			text-align: left;
		}
	}
	.num-title{
		font-size: 20px;
		font-weight: bold;
		color: #333;
		text-align: center;
		padding: 20px 0px;
	}
	.dialog-footer {
		margin-top: 30px;
	
		.dialog-footer-one {
			width: 114px;
			height: 34px;
			border: 1px solid #FF8D1F;
			background: #fff;
			border-radius: 6px;
			font-weight: 400;
			font-size: 14px;
			color: #FF8D1F;
			line-height: 34px;
			text-align: center;
			margin: 0px 20px;
			cursor: pointer;
			padding: 0px;
		}
	}
	::v-deep .addModel{
		border-radius: 10px !important;
	}
	::v-deep .el-dialog__title{
		height: 0px;
	}
	::v-deep .el-dialog--center .el-dialog__body{
		padding: 0px;
	}
</style>