<template>
	<div class="info-div">
		<div class="info" v-if="show">
			<div class="info-title flex-between flex-center">
				<div>创建自组试卷</div>
				<img src="@/assets/images/close.png" @click="closeNow"/>
			</div>
			<div class="info-content flex-between">
				<div class="info-content-left">
					<div class="info-content-left-title">{{previewInfo.name}}试卷：{{previewInfo.paperScore}}分-{{previewInfo.questionList.length}}道</div>
					<div class="info-content-left-list flex-center flex-midden">
						<div class="info-content-left-item">
							<div class="info-content-left-item-top">
								<div>{{previewInfo.singleNum}}</div>
								<div>单选题</div>
							</div>
						</div>
						<div class="info-content-left-item">
							<div class="info-content-left-item-top" style="background-color: #598DFF;">
								<div>{{previewInfo.mutipleNum}}</div>
								<div>多选题</div>
							</div>
						</div>
						<div class="info-content-left-item">
							<div class="info-content-left-item-top" style="background-color: #6CC37F;">
								<div>{{previewInfo.writtenNum}}</div>
								<div>笔试题</div>
							</div>
						</div>
					</div>
					
					
					<div class="info-content-left-dif flex-center flex-start flex-wrap" style="padding-left:20px;">
						<div class="info-content-left-item" style="width: 33%;text-align: left;">
							<div class="info-content-left-item-bottom" style="text-align: left;padding:0px 5px;;">创建老师：{{previewInfo.nikeName}}</div>
						</div>
						<div class="info-content-left-item" style="width: 33%;text-align: left;">
							<div class="info-content-left-item-bottom" style="text-align: left;padding:0px 5px;;">手机号：{{previewInfo.mobile}}</div>
						</div>
						<div class="info-content-left-item" style="width: 33%;text-align: left;">
							<div class="info-content-left-item-bottom" style="text-align: left;padding:0px 5px;;">题目类型：{{previewInfo.questionType==1?'选择题':(previewInfo.questionType==2?"综合题":'')}}</div>
						</div>
						<div class="info-content-left-item" style="width: 33%;text-align: left;">
							<div class="info-content-left-item-bottom" style="text-align: left;padding:0px 5px;;">试卷类型：{{findName()}}</div>
						</div>
						<div class="info-content-left-item" style="width: 33%;text-align: left;">
							<div class="info-content-left-item-bottom" style="text-align: left;padding:0px 5px;;">考卷密码：{{previewInfo.password}}</div>
						</div>
						<div class="info-content-left-item" style="width: 33%;text-align: left;">
							<div class="info-content-left-item-bottom" style="text-align: left;padding:0px 5px;;">是否指定作答：{{previewInfo.participants==1?'是':"否"}}</div>
						</div>
						<div class="info-content-left-item" style="width: 33%;text-align: left;">
							<div class="info-content-left-item-bottom" style="text-align: left;padding:0px 5px;;">判卷类型：{{previewInfo.examination==1?'自己判卷':'平台分配判卷'}}</div>
						</div>
						<div class="info-content-left-item" style="width: 66%;">
							<div class="info-content-left-item-bottom" style="text-align: left;padding:0px 5px;;">创建时间：{{previewInfo.createTime}}</div>
						</div>
					</div>
					
					<div class="info-content-left-dif flex-center flex-midden flex-wrap">
						<div v-for="item in previewInfo.questionDiffList" :key="'q'+item.name" class="info-content-left-item">
							<div class="info-content-left-item-bottom">{{item.name}}：{{item.ratio}}</div>
						</div>
					</div>
					
					<div class="info-content-left-info">
						<template v-for="(item, index) in previewInfo.questionKnowledge">
							<div :key="index" class="info-content-left-info-left flex-midden">
								<div class="title-one">{{item.name}}</div>
								<div class="title-one">{{item.total}}道题({{item.ratio}})</div>
							</div>
							<template v-for="(value, key) in item.children">
								<div :key="key" class="info-content-left-info-left flex-midden">
									<div class="title-two">{{value.name}}</div>
									<div class="title-two">{{value.total}}道题({{value.ratio}})</div>
								</div>
								<template v-for="(v, k) in value.children">
									<div :key="k" class="info-content-left-info-left flex-midden">
										<div class="title-three">{{v.name}}</div>
										<div class="title-three">{{v.total}}道题({{v.ratio}})</div>
									</div>
								</template>
							</template>
						</template>
					</div>
					<div class="info-content-left-button flex-midden flex-center">
						<div @click="closeNow">返回</div>
						<div @click="download">试卷下载</div>
					</div>
				</div>
				
				<div class="info-content-right">
					<div class="info-content-right-hr" v-for="(item, index)  in previewInfo.questionList" :key="item.id">
						<div class="index-overlay">
							{{ index + 1 }}
						</div>
						<img style="width: 100%;height: auto;" :src="item.imageUrl"/>
						<div class="info-content-right-score">分值 {{item.score}}分</div>
						<div class="info-content-right-answer flex-start">题目答案<span v-if="item.questionType == 1">：{{item.answer}}</span></div>
						<img style="width: 100%;height: auto;" v-if="item.questionType != 1" :src="item.answerUrl"/>
						<div style="height: 20px;"></div>
					</div>
				</div>
			</div>
		</div>
		<downPdf ref="downPdfRef" @ok="downloadSubmit"></downPdf>
	</div>
</template>

<script>
	
	import {paperPreview,paperDownload} from "@/api/index"
	import downPdf from "@/components/downPdf"
	import {paperTypes} from "@/api/config"
	export default {
		components:{
			downPdf
		},
		data(){
			return {
				id:0,
				previewInfo:{},
				paperTypeList:[],
				show:false
			}
		},
		mounted() {
			this.id = this.$route.query.id || 0;
			//获取试卷类型
			paperTypes().then(res=>{this.paperTypeList = res.data;})
			paperPreview({paperId:this.id}).then(res=>{
				if (res.code == 0) {
					this.show = true;
					this.previewInfo = res.data;
				} else {
					this.$message.error(res.msg);
				}
			})
		},
		methods:{
			closeNow(){
				window.close();
			},
			//下载试卷
			download(){
				this.$refs.downPdfRef.init({id:this.id});
				
			},
			downloadSubmit(e){
				paperDownload({id:e.id,type:e.type}).then(res=>{
					const url = window.URL.createObjectURL(new Blob([res]))
					const link = document.createElement("a")
					link.href = url
					if(e.type == 1){
						link.setAttribute("download",'教师版.pdf')//导出的格式一定要和接口返回的文件一致
					}else{
						link.setAttribute("download",'学生版.pdf')//导出的格式一定要和接口返回的文件一致
					}
					
					document.body.appendChild(link)
					link.click()
					window.URL.revokeObjectURL(url)
					document.body.removeChild(link)
				})
			},
			findName(){
				let info = this.paperTypeList.find(item=>{return item.id == this.previewInfo.paperType});
				
				return info?info.name:'';
			}
		}
	}
</script>


<style lang="scss" scoped>

.info-content-right-hr {
	position: relative; /* 添加相对定位 */
}

.index-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 24px;
	height: 24px;
	background-color: white;
	z-index: 1; /* 确保遮挡层在图片上方 */
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	font-weight: bold;
}
	.info-div{
		margin-top: 130px;
	}
	@import "../assets/info.scss";
</style>