<template>
	<div class="create">
		<div class="content-table-nav flex-start flex-center" v-if="step != 3">
			<div @click="toIndex">自组试卷列表</div>
			<div @click="topager">自组判卷列表</div>
			<div class="active">新建自组试卷</div>
		</div>
		<div v-if="step == 2" class="create-question flex-start flex-center">
			
			
			<!-- <template v-if="edit == false">
				<div class="create-question-icon flex-center flex-midden" v-if="showLeft" @click="showLeft=false">
					<img src="@/assets/images/create/shou.png"/>
					收起
				</div>
				<div class="create-question-icon flex-center flex-midden" v-else @click="showLeft=true">
					<img src="@/assets/images/create/shou.png"/>
					展开
				</div>
			</template> -->
			<el-row style="width: 100%;">
				<el-col :span="6">
					<div class="create-item flex-start flex-center">
						<div class="form-two-title">自组试卷名称</div>
						<el-input type="text" v-model="setting.name" placeholder="请输入自组试卷名称"></el-input>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="create-item flex-start flex-center">
						<div class="form-two-title">题目类型</div>
						<el-select v-model="setting.questionType" placeholder="请选择">
							<!-- <el-option v-for="item in twoData.questionTypeList" :label="item.name" :value="item.id"></el-option> -->
							<el-option label="选择题" :value="1"></el-option>
							<el-option label="综合题" :value="2"></el-option>
						</el-select>
						
					</div>
				</el-col>
				<el-col :span="6">
					<div class="create-item flex-start flex-center">
						<div class="form-two-title">试卷类型</div>
						<el-select v-model="setting.paperType" placeholder="请选择">
							<el-option v-for="item in paperTypeList" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="create-item flex-start flex-center">
						<div class="form-two-title">真题试卷密码</div>
						<el-input type="text" v-model="setting.password" placeholder="请输入真题试卷密码"></el-input>
					</div>
				</el-col>
			</el-row>
		</div>
		<div class="create-top" v-if="step == 1">
			<div class="create-title flex-start flex-center">
				<img src="@/assets/images/create/icon1.png" />
				自组试卷抽取条件<span style="font-size: 14px;color: #333;">（筛选均为非必填）</span>
			</div>
			
			<el-row>
				<el-col :span="4">
					<div class="create-item flex-start flex-center">
						<div class="form-title">考试局</div>
						<!-- <el-select v-model="form.exam_offices" collapse-tags multiple clear filterable placeholder="请选择,不选表示全部">
							<el-option v-for="item in examname" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select> -->
						<el-cascader v-model="form.exam_offices" filterable style="width: 100%;"  placeholder="请选择,不选表示全部" collapse-tags :show-all-levels="false" :options="examname" :props="{ multiple: true,value:'id',label:'name',emitPath:false }" clearable></el-cascader>
					</div>
				</el-col>
				<el-col :span="4">
					<div class="create-item flex-start flex-center">
						<div class="form-title">科目</div>
						<!-- <el-select v-model="form.subjects" @change="getOneKonwled" collapse-tags multiple clear filterable placeholder="请选择,不选表示全部">
							<el-option v-for="item in subject" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select> -->
						<el-cascader v-model="form.subjects" @change="getOneKonwled" filterable style="width: 100%;"  placeholder="请选择,不选表示全部" collapse-tags :show-all-levels="false" :options="subject" :props="{ multiple: true,value:'id',label:'name',emitPath:false }" clearable></el-cascader>
					</div>
				</el-col>
				<el-col :span="4">
					<div class="create-item flex-start flex-center">
						<div class="form-title">考年</div>
						<!-- <el-select v-model="form.years" collapse-tags multiple clear filterable placeholder="请选择,不选表示全部">
							<el-option v-for="item in yearList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select> -->
						<el-cascader v-model="form.years" filterable style="width: 100%;"  placeholder="请选择,不选表示全部" collapse-tags :show-all-levels="false" :options="yearList" :props="{ multiple: true,value:'id',label:'name',emitPath:false }" clearable></el-cascader>
					</div>
				</el-col>
				<el-col :span="4">
					<div class="create-item flex-start flex-center">
						<div class="form-title">考季</div>
						<!-- <el-select v-model="form.exam_seasons" collapse-tags multiple clear filterable placeholder="请选择,不选表示全部">
							<el-option v-for="item in examseasonList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select> -->
						<el-cascader v-model="form.exam_seasons" filterable style="width: 100%;"  placeholder="请选择,不选表示全部" collapse-tags :show-all-levels="false" :options="examseasonList" :props="{ multiple: true,value:'id',label:'name',emitPath:false }" clearable></el-cascader>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="create-item flex-start flex-center">
						<div class="form-title" style="flex: 0 0 120px;">单题分值区间</div>
						<el-input type="number" v-model="form.min_score" placeholder="请输入数字"></el-input>
						<div>至</div>
						<el-input type="number" v-model="form.max_score" placeholder="请输入数字"></el-input>
					</div>
				</el-col>
			
			</el-row>
		
			<el-row>
				<el-col :span="4">
					<div class="create-item flex-start flex-center">
						<div class="form-title">抽取数量</div>
						<el-input type="number" v-model="form.question_num" placeholder="请输入数字"></el-input>
					</div>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="4">
					<div class="create-item flex-start flex-center">
						<div class="form-title">知识点一级</div>
						<div class="form-input">
							<div class="form-input-value flex-between flex-center">
								<div>{{getvalue(oneKnowLed)}}</div>
								<img src="@/assets/images/create/bottom.png"/>
							</div>
							<div class="form-input-search">
								<div class="form-input-search-item flex-between flex-center" v-for="(item,index) in oneKnowLed">
									<div class="form-input-search-item-check">
										<el-checkbox v-model="oneKnowLed[index].checked" @change="getTwoKonwled(item.id)">{{ item.name }}</el-checkbox>
									</div>
									<el-input style="width: 150px;" type="number" v-model="oneKnowLed[index].num" placeholder="请输入权重%"></el-input>
								</div>
							</div>
						</div>
					</div>
				</el-col>
				<el-col :span="4" :offset="10">
					<div class="create-item flex-start flex-center">
						<div class="form-title">难度</div>
						<div class="form-input">
							<div class="form-input-value flex-between flex-center">
								<div>{{getvalue(difficultiesList)}}</div>
								<img src="@/assets/images/create/bottom.png"/>
							</div>
							<div class="form-input-search">
								<div class="form-input-search-item flex-between flex-center" v-for="(item,index) in difficultiesList">
									<div class="form-input-search-item-check">
										<el-checkbox v-model="difficultiesList[index].checked">{{ item.name }}</el-checkbox>
									</div>
									<el-input style="width: 150px;" type="number" v-model="difficultiesList[index].num" placeholder="请输入权重%"></el-input>
								</div>
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
			<el-row v-if="twoKnowLed.length > 0">
				<el-col :span="24">
					<div class="create-item flex-start flex-center">
						<div class="form-title">知识点二级</div>
						<el-row style="width: 100%;" :gutter="20">
							<el-col :span="4" v-for="item in twoKnowLed">
								<div class="form-input">
									<div class="form-input-value flex-between flex-center">
										<div>{{getvalue(item.list)}}</div>
										<img src="@/assets/images/create/bottom.png"/>
									</div>
									<div class="form-input-search">
										<div class="form-input-search-item flex-between flex-center" v-for="(value,key) in item.list">
											<div class="form-input-search-item-check">
												<el-checkbox v-model="item.list[key].checked" @change="getThreeKonwled(value.id)">{{ value.name }}</el-checkbox>
											</div>
											<el-input style="width: 150px;" type="number" v-model="item.list[key].num" placeholder="请输入权重%"></el-input>
										</div>
									</div>
								</div>
							</el-col>
						</el-row>
					</div>
				</el-col>
			</el-row>
			<el-row v-if="threeKnowLed.length > 0">
				<el-col :span="24">
					<div class="create-item flex-start flex-center">
						<div class="form-title">知识点三级</div>
						<el-row style="width: 100%;" :gutter="20">
							<el-col :span="4" v-for="item in threeKnowLed">
								<div class="form-input">
									<div class="form-input-value flex-between flex-center">
										<div>{{getvalue(item.list)}}</div>
										<img src="@/assets/images/create/bottom.png"/>
									</div>
									<div class="form-input-search">
										<div class="form-input-search-item flex-between flex-center" v-for="(value,key) in item.list">
											<div class="form-input-search-item-check">
												<el-checkbox v-model="item.list[key].checked">{{ value.name }}</el-checkbox>
											</div>
											<el-input style="width: 150px;" type="number" v-model="item.list[key].num" placeholder="请输入权重%"></el-input>
										</div>
									</div>
								</div>
							</el-col>
						</el-row>
					</div>
				</el-col>
			</el-row>
			<div class="create-notice">提示：单下拉框内容的百分总和需等于100%</div>
			
			
			<div class="bottom-button flex-start flex-center">
				 <div @click="selectQuestion">检索题目</div>
			</div>
		</div>
		
		<div class="create-bottom" v-if="step == 1">
			<div class="create-title flex-start flex-center">
				<img src="@/assets/images/create/icon2.png" />
				自组试卷组合条件
			</div>
			
			<el-row>
				<el-col :span="6">
					<div class="create-item flex-start flex-center">
						<div class="form-two-title">自组试卷类型</div>
						<el-select v-model="type" placeholder="请选择">
							<el-option label="考卷题数量" :value="1"></el-option>
							<el-option label="考卷总分值" :value="2"></el-option>
						</el-select>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="create-item flex-start flex-center">
						<div class="form-two-title">单选题(数量)</div>
						<el-input type="number" v-model="form.single_question_num" placeholder="请输入数字"></el-input>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="create-item flex-start flex-center">
						<div class="form-two-title">多选题(数量)</div>
						<el-input type="number" v-model="form.multiple_question_num" placeholder="请输入数字"></el-input>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="create-item flex-start flex-center">
						<div class="form-two-title">笔试题(数量)</div>
						<el-input type="number" v-model="form.written_question_num" placeholder="请输入数字"></el-input>
					</div>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="6">
					<div class="create-item flex-start flex-center">
						<div v-if="type == 1" class="form-two-title">考卷题数量</div>
						<div v-if="type == 2" class="form-two-title">考卷总分值</div>
						<el-input v-if="type == 1" type="number" v-model="form.paper_num" placeholder="输入考题数量不可超过100"></el-input>
						<el-input v-if="type == 2" type="number" v-model="form.paper_score" placeholder="请输入数字"></el-input>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="create-item flex-start flex-center">
						<div class="form-two-title">答案数量</div>
						<el-input type="number" v-model="form.single_answer_num" placeholder="请输入数字"></el-input>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="create-item flex-start flex-center">
						<div class="form-two-title">答案数量</div>
						<el-input type="number" v-model="form.multiple_answer_num" placeholder="请输入数字"></el-input>
					</div>
				</el-col>
			</el-row>
			<el-radio-group v-model="form.examination" style="width: 100%;">
				<el-row style="width: 100%;">
					<el-col :span="6">
						<div class="create-item flex-start flex-center">
							<div class="form-two-title"></div>
							<el-radio :label="1">自己判卷</el-radio>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="create-item flex-start flex-center">
							<div class="form-two-title"></div>
							<el-radio :label="2">平台分配判卷</el-radio>
						</div>
					</el-col>
				</el-row>
			 </el-radio-group>
			 
			 <el-row v-if="form.examination == 1">
			 	<el-col :span="6">
			 		<div class="create-item flex-start flex-center">
			 			<div class="form-two-title"></div>
			 			
			 			<el-select v-model="form.participants" placeholder="请选择">
			 				<el-option label="指定作答" :value="1"></el-option>
			 				<el-option label="不指定作答" :value="2"></el-option>
			 			</el-select>
			 		</div>
			 	</el-col>
			 </el-row>
			 <div class="num" v-if="num > 0">根据当前检索条件搜索到了 <span>{{num}}</span> 道题</div>
			 
			 <div class="bottom-button flex-start flex-center">
				 <div @click="reset">重置</div>
				 <div @click="submit">创建真题试卷</div>
			 </div>
		</div>
		
		
		
		<div class="create-top" v-if="step == 2" style="margin-bottom: 20px;">
			<el-row>
				<el-col :span="6">
					<div class="create-item flex-start flex-center">
						<div class="form-title">考试局</div>
						<el-tooltip class="item" effect="dark" placement="top-start" v-if="queryInfo.query.exam_offices.length > 0">
							<div slot="content" style="width: 200px;"><span v-for="item in twoData.examname" :key="item.id" style="margin-right: 5px;">{{item.name}}</span></div>
							<div class="form-value one">
								<span v-for="item in twoData.examname" :key="item.id" style="margin-right: 5px;">{{item.name}}</span>
							</div>
						</el-tooltip>
						<div class="form-value one" v-else></div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="create-item flex-start flex-center">
						<div class="form-title">科目</div>
						<el-tooltip class="item" effect="dark" placement="top-start" v-if="queryInfo.query.subjects.length > 0">
							<div slot="content" style="width: 200px;"><span v-for="item in twoData.subject" :key="item.id" style="margin-right: 5px;">{{item.name}}</span></div>
							<div class="form-value one">
								<span v-for="item in twoData.subject" :key="item.id" style="margin-right: 5px;">{{item.name}}</span>
							</div>
						</el-tooltip>
						<div class="form-value one" v-else></div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="create-item flex-start flex-center">
						<div class="form-title">考年</div>
						<el-tooltip class="item" effect="dark" placement="top-start" v-if="queryInfo.query.years.length > 0">
							<div slot="content" style="width: 200px;"><span v-for="item in twoData.yearList" :key="item.id" style="margin-right: 5px;">{{item.name}}</span></div>
							<div class="form-value one">
								<span v-for="item in twoData.yearList" :key="item.id" style="margin-right: 5px;">{{item.name}}</span>
							</div>
						</el-tooltip>
						<div class="form-value one" v-else></div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="create-item flex-start flex-center">
						<div class="form-title">考季</div>
						<el-tooltip class="item" effect="dark" placement="top-start" v-if="queryInfo.query.exam_seasons.length > 0">
							<div slot="content" style="width: 200px;"><span v-for="item in twoData.examseasonList" :key="item.id" style="margin-right: 5px;">{{item.name}}</span></div>
							<div class="form-value one">
								<span v-for="item in twoData.examseasonList" :key="item.id" style="margin-right: 5px;">{{item.name}}</span>
							</div>
						</el-tooltip>
						<div class="form-value one" v-else></div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="create-item flex-start flex-center">
						<div class="form-title">一级知识点</div>
						<el-tooltip class="item" effect="dark" placement="top-start" v-if="queryInfo.query.knowledge_points1.length > 0">
							<div slot="content" style="width: 200px;"><span v-for="item in twoData.one_knowLed" :key="item.id" style="margin-right: 5px;">{{item.name}}</span></div>
							<div class="form-value one">
								<span v-for="item in twoData.one_knowLed" :key="item.id" style="margin-right: 5px;">{{item.name}}</span>
							</div>
						</el-tooltip>
						<div class="form-value one" v-else></div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="create-item flex-start flex-center">
						<div class="form-title">二级知识点</div>
						<el-tooltip class="item" effect="dark" placement="top-start" v-if="queryInfo.query.knowledge_points2.length > 0">
							<div slot="content" style="width: 200px;"><span v-for="item in twoData.two_knowLed" :key="item.id" style="margin-right: 5px;">{{item.name}}</span></div>
							<div class="form-value one">
								<span v-for="item in twoData.two_knowLed" :key="item.id" style="margin-right: 5px;">{{item.name}}</span>
							</div>
						</el-tooltip>
						<div class="form-value one" v-else></div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="create-item flex-start flex-center">
						<div class="form-title">三级知识点</div>
						<el-tooltip class="item" effect="dark" placement="top-start" v-if="queryInfo.query.knowledge_points3.length > 0">
							<div slot="content" style="width: 200px;"><span v-for="item in twoData.three_knowLed" :key="item.id" style="margin-right: 5px;">{{item.name}}</span></div>
							<div class="form-value one">
								<span v-for="item in twoData.three_knowLed" :key="item.id" style="margin-right: 5px;">{{item.name}}</span>
							</div>
						</el-tooltip>
						<div class="form-value one" v-else></div>
					</div>
				</el-col>
				<el-col :span="6">
					<div class="create-item flex-start flex-center">
						<div class="form-title">难度</div>
						<el-tooltip class="item" effect="dark" placement="top-start" v-if="queryInfo.query.difficulty.length > 0">
							<div slot="content" style="width: 200px;"><span v-for="item in twoData.difficultiesList" :key="item.id" style="margin-right: 5px;">{{item.name}}</span></div>
							<div class="form-value one">
								<span v-for="item in twoData.difficultiesList" :key="item.id" style="margin-right: 5px;">{{item.name}}</span>
							</div>
						</el-tooltip>
						<div class="form-value one" v-else></div>
					</div>
				</el-col>
				<el-col :span="3" v-if="queryInfo.query">
					<div class="create-item flex-start flex-center">
						<div class="form-title">抽取数量</div>
						<div class="form-value one">
							{{queryInfo.query.question_num}}
						</div>
					</div>
				</el-col>
				<el-col :span="4" v-if="queryInfo.query">
					<div class="create-item flex-start flex-center">
						<div class="form-title" style="flex: 0 0 120px;">单题分值区间</div>
						<div class="form-value">
							{{queryInfo.query.min_score}}
						</div>
						<div>至</div>
						<div class="form-value">
							{{queryInfo.query.max_score}}
						</div>
					</div>
				</el-col>
				<el-col :span="5" v-if="queryInfo.paper">
					<div class="create-item flex-start flex-center">
						<div class="form-title">自组试卷类型</div>
						<div class="form-value" style="width: 60%;">
							{{queryInfo.paper.paperNum > 0?'考卷题数量':'考卷总分值'}}
						</div>
						<div class="form-value" style="margin-left: 10px;width: 40%;">
							{{queryInfo.paper.paperNum > 0?queryInfo.paper.paperNum:queryInfo.paper.paperScore}}
						</div>
					</div>
				</el-col>
				<el-col :span="3" v-if="queryInfo.paper">
					<div class="create-item flex-start flex-center">
						<div class="form-title">单选题(数量)</div>
						<div class="form-value">
							{{queryInfo.paper.singleNum}}
						</div>
					</div>
				</el-col>
				<el-col :span="3" v-if="queryInfo.paper">
					<div class="create-item flex-start flex-center">
						<div class="form-title">多选题(数量)</div>
						<div class="form-value">
							{{queryInfo.paper.mutipleNum}}
						</div>
					</div>
				</el-col>
				<el-col :span="3" v-if="queryInfo.paper">
					<div class="create-item flex-start flex-center">
						<div class="form-title">笔试题(数量)</div>
						<div class="form-value">
							{{queryInfo.paper.writtenNum}}
						</div>
					</div>
				</el-col>
				<el-col :span="3" v-if="queryInfo.paper">
					<div class="create-item flex-start flex-center">
						<div class="form-title" style="flex: 0 0 60px;">判卷类型</div>
						<div class="form-value">
							{{queryInfo.paper.examination==1?'自己判卷':'平台分配判卷'}}
						</div>
					</div>
				</el-col>
				 
			</el-row>
			
		</div>
	
		<div class="create-result flex-between flex-center" v-if="step == 2">
			<div class="create-result-left-div" v-if="showLeft">
				<div class="create-result-left">
					<div class="flex-start flex-center">
						<template v-if="edit == false">
							<div class="create-question-icon flex-center flex-midden" v-if="showLeft" @click="showLeft=false">
								<img src="@/assets/images/create/shou.png"/>
								收起
							</div>
							<div class="create-question-icon flex-center flex-midden" v-else @click="showLeft=true">
								<img src="@/assets/images/create/shou.png"/>
								展开
							</div>
						</template>
						<div class="create-result-left-title flex-start flex-center">
							<img src="@/assets/images/create/title-search.png"/>
							<div>检索题目列表</div>
						</div>
					</div>
					<div class="create-result-left-content" v-infinite-scroll="getNextData" :infinite-scroll-immediate="false" :infinite-scroll-disabled="leftResult.list.length > 0?false:true">
						<div class="create-result-left-search flex-between">
							<div class="create-result-left-search-left" :style="!showSearch?'height: 40px;':''" v-if="showtwoSearch">
								<el-row>
									<el-col :span="12">
										<div class="create-item flex-start flex-center">
											<div class="form-title" style="flex: 0 0 60px;">知识点</div>
											 <el-cascader v-model="search.knowled" :options="twoData.knowLed" size="mini" style="width: 100%;" :show-all-levels="false" :props="{multiple: true,checkStrictly: true,value:'id',label:'name',children:'children'}" collapse-tags clearable></el-cascader>
										</div>
									</el-col>
									<el-col :span="12">
										<div class="create-item flex-start flex-center">
											<div class="form-title" style="flex: 0 0 60px;">考试局</div>
											<el-select v-model="search.exam_offices" size="mini" style="width: 100%;" collapse-tags multiple clear filterable placeholder="请选择">
												<el-option v-for="item in twoData.examname" :key="item.id" :label="item.name" :value="item.id"></el-option>
											</el-select>
										</div>
									</el-col>
									<el-col :span="8">
										<div class="create-item flex-start flex-center">
											<div class="form-title" style="flex: 0 0 40px;">科目</div>
											<el-select v-model="search.subjects" size="mini" style="width: 100%;" collapse-tags multiple clear filterable placeholder="请选择">
												<el-option v-for="item in twoData.subject" :key="item.id" :label="item.name" :value="item.id"></el-option>
											</el-select>
										</div>
									</el-col>
									<el-col :span="8">
										<div class="create-item flex-start flex-center">
											<div class="form-title" style="flex: 0 0 40px;">考年</div>
											<el-select v-model="search.years" size="mini" style="width: 100%;" collapse-tags multiple clear filterable placeholder="请选择">
												<el-option v-for="item in twoData.yearList" :key="item.id" :label="item.name" :value="item.id"></el-option>
											</el-select>
										</div>
									</el-col>
									<el-col :span="8">
										<div class="create-item flex-start flex-center">
											<div class="form-title" style="flex: 0 0 70px;">题目类型</div>
											<el-select v-model="search.questionType" size="mini" style="width: 100%;" clear filterable placeholder="请选择">
												<el-option v-for="item in twoData.questionTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
											</el-select>
										</div>
									</el-col>
									<el-col :span="16">
										<div class="create-item flex-start flex-center">
											<div class="form-title" style="flex: 0 0 100px;">单题分值区间</div>
											<el-input type="number" size="mini" style="width: 100%;" v-model="search.min_score" placeholder="请输入数字"></el-input>
											<div>至</div>
											<el-input type="number" size="mini" style="width: 100%;" v-model="search.max_score" placeholder="请输入数字"></el-input>
										</div>
									</el-col>
									<el-col :span="8">
										<div class="create-item flex-start flex-center">
											<div class="form-title" style="flex: 0 0 40px;">考季</div>
											<el-select v-model="search.exam_seasons" size="mini" style="width: 100%;" collapse-tags multiple clear filterable placeholder="请选择">
												<el-option v-for="item in twoData.examseasonList" :key="item.id" :label="item.name" :value="item.id"></el-option>
											</el-select>
										</div>
									</el-col>
									<el-col :span="8">
										<div class="create-item flex-start flex-center">
											<div class="form-title" style="flex: 0 0 40px;">难度</div>
											<el-select v-model="search.difficulty" size="mini" style="width: 100%;" collapse-tags multiple clear filterable placeholder="请选择">
												<el-option v-for="item in twoData.difficultiesList" :key="item.id" :label="item.name" :value="item.id"></el-option>
											</el-select>
										</div>
									</el-col>
									<el-col :span="8">
										<div class="create-item flex-start flex-center">
											<div class="create-item-button" @click="twoDataSearch">搜索</div>
											<div class="create-item-two" @click="twoDataReset">重置</div>
										</div>
									</el-col>
								</el-row>
							</div>
							<div class="create-result-left-search-right" v-if="showSearch" @click="showSearch = false">收起<i class="el-icon-arrow-up"></i></div>
							<div class="create-result-left-search-right" v-else @click="showSearch = true">展开<i class="el-icon-arrow-up"></i></div>
						</div>
						
						<div class="create-result-left-list flex-start flex-center flex-wrap">
							<div v-for="(item,index) in leftResult.list" :key="item.id" class="create-result-left-item">
								<img v-if="selectIds.indexOf(item.id) > -1" src="@/assets/images/select.png" @click.stop="actionSelect(item)" class="create-result-left-item-icon"/>
								<img v-else src="@/assets/images/noselect.png" @click.stop="actionSelect(item)" class="create-result-left-item-icon"/>
								 <el-image class="create-result-left-item-bg" @click.stop="seeQuestion(index)" :src="item.imageUrl" fit="contain"></el-image>
							</div>
						</div>
						
						<div class="getMoreInfo" v-if="this.leftResult.isLoad == true" @click="getNextData">点击加载更多</div>
					</div>
					<div class="create-result-bottom flex-center flex-midden">
						<div class="create-result-bottom-left" @click="addLeftAll">全选</div>
						<div class="create-result-bottom-center" @click="addMoreQuestion">加入试卷({{selectIds.length}}）</div>
						<div class="create-result-bottom-right" v-if="selectIds.length > 0" @click="clearLeftAll">清空选择</div>
					</div>
				</div>
			</div>
			<div class="create-result-right-div" :class="!showLeft?'show-right':''">
				<div class="create-result-right" :class="!showLeft?'show-right':''">
					<template v-if="edit == false">
						<div class="create-question-icon flex-center flex-midden" style="margin-top: 20px;" v-if="!showLeft" @click="showLeft=true">
							<img src="@/assets/images/create/shou.png"/>
							展开
						</div>
					</template>
					<div class="create-result-item" v-for="(item,index) in questionList" :key="item.id">
						<div class="create-result-title flex-start flex-center" style="flex-wrap: wrap;">
							<div class="create-result-title-input flex-start flex-center">
								<div>题号</div>
								<input type="number" v-model="item.sort" placeholder="请输入题号" @blur="updateInfo(item,1)"/>
							</div>
							<div class="create-result-title-input flex-start flex-center">
								<div>题目分值</div>
								<input type="number" v-model="item.score" placeholder="请输入题目分值" @blur="updateInfo(item,2)"/>
							</div>
						
							<div class="create-result-title-input flex-start flex-center">
								<div>左距</div>
								<input type="number" v-model="item.left_v" placeholder="" @blur="updateInfo(item,1)"/>
							</div>
							<div class="create-result-title-input flex-start flex-center">
								<div>上距</div>
								<input type="number" v-model="item.top_v" placeholder="" @blur="updateInfo(item,1)"/>
							</div>
							<div class="create-result-title-input flex-start flex-center">
								<div>字号</div>
								<input type="number" v-model="item.size_v" placeholder="" @blur="updateInfo(item,1)"/>
							</div>
							<div class="create-result-change" @click="changeQuestion(item)">切题</div>
							<div class="create-result-delete" @click="deleteimage(item.id)">删除该题</div>
							<div class="create-result-see" @click="seepassword(index)">查看答案</div>
						</div>
						
						<img style="width: 100%;height: auto;" :src="item.imageUrl"/>
					</div>
					<div style="height: 100px;"></div>
					<div class="create-result-bottom flex-center flex-midden">
						<!-- <div class="create-result-bottom-left" @click="seePager">生成序号</div> -->
						<div class="create-result-bottom-left" @click="seePager">试卷预览</div>
						<div class="create-result-bottom-center" @click="download">试卷下载</div>
						<div class="create-result-bottom-right" @click="submitPage">试卷保存({{totalscore}}分)</div>
						<div style="font-size: 12px;color: #333;flex: 0 0 80px;text-align: left;">
							<div>选择题：{{singleNum}}</div>
							<div>笔试题：{{mutipleNum}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<notice ref="noticeRef" @ok="noticeSubmit"></notice>
		<downPdf ref="downPdfRef" @ok="downloadSubmit"></downPdf>
		<!-- 题目展示详情 -->
		<showQuestion ref="showQuestionRef" @update="changeLeftInfo" @ok="showError"></showQuestion>
		<!-- 切题 -->
		<updateQuestion ref="updateQuestionRef" @update="getTestInfo"></updateQuestion>
		<!-- 错误体型 -->
		<error ref="errorRef"></error>
		<!-- 查看答案 -->
		<showPassword ref="showPasswordRef" @update="getTestInfo"></showPassword>
		
		<!-- 试卷详情 -->
		<div class="info" v-if="step == 3">
			<div class="info-title flex-between flex-center">
				<div>创建自组试卷</div>
				<img src="@/assets/images/close.png" @click="closeSeePager"/>
			</div>
			<div class="info-content flex-between">
				<div class="info-content-left">
					<div class="info-content-left-title">{{setting.name}}试卷：{{totalscore}}分-{{previewInfo.questionList.length}}道</div>
					<div class="info-content-left-list flex-center flex-midden">
						<div class="info-content-left-item">
							<div class="info-content-left-item-top">
								<div>{{previewInfo.singleNum?previewInfo.singleNum:0}}</div>
								<div>单选题</div>
							</div>
						</div>
						<div class="info-content-left-item">
							<div class="info-content-left-item-top" style="background-color: #598DFF;">
								<div>{{previewInfo.mutipleNum?previewInfo.mutipleNum:0}}</div>
								<div>多选题</div>
							</div>
						</div>
						<div class="info-content-left-item">
							<div class="info-content-left-item-top" style="background-color: #6CC37F;">
								<div>{{previewInfo.writtenNum?previewInfo.writtenNum:0}}</div>
								<div>笔试题</div>
							</div>
						</div>
					</div>
					
					<div class="info-content-left-dif flex-center flex-start flex-wrap" style="padding-left:20px;">
						<div class="info-content-left-item" style="width: 33%;text-align: left;">
							<div class="info-content-left-item-bottom" style="text-align: left;padding:0px 5px;;">创建老师：{{previewInfo.nikeName}}</div>
						</div>
						<div class="info-content-left-item" style="width: 33%;text-align: left;">
							<div class="info-content-left-item-bottom" style="text-align: left;padding:0px 5px;;">手机号：{{previewInfo.mobile}}</div>
						</div>
						<div class="info-content-left-item" style="width: 33%;text-align: left;">
							<div class="info-content-left-item-bottom" style="text-align: left;padding:0px 5px;;">题目类型：{{setting.questionType==1?'选择题':(setting.questionType==2?"综合题":'')}}</div>
						</div>
						<div class="info-content-left-item" style="width: 33%;text-align: left;">
							<div class="info-content-left-item-bottom" style="text-align: left;padding:0px 5px;;">试卷类型：{{findName()}}</div>
						</div>
						<div class="info-content-left-item" style="width: 33%;text-align: left;">
							<div class="info-content-left-item-bottom" style="text-align: left;padding:0px 5px;;">考卷密码：{{setting.password}}</div>
						</div>
						<div class="info-content-left-item" style="width: 33%;text-align: left;">
							<div class="info-content-left-item-bottom" style="text-align: left;padding:0px 5px;;">是否指定作答：{{queryInfo.paper.participants==1?'是':"否"}}</div>
						</div>
						<div class="info-content-left-item" style="width: 33%;text-align: left;">
							<div class="info-content-left-item-bottom" style="text-align: left;padding:0px 5px;;">判卷类型：{{queryInfo.paper.examination==1?'自己判卷':'平台分配判卷'}}</div>
						</div>
						<div class="info-content-left-item" style="width: 66%;">
							<div class="info-content-left-item-bottom" style="text-align: left;padding:0px 5px;;">创建时间：{{previewInfo.createTime}}</div>
						</div>
					</div>
					<div class="info-content-left-dif flex-center flex-midden flex-wrap">
						<div v-for="item in previewInfo.questionDiffList" :key="'q'+item.name" class="info-content-left-item">
							<div class="info-content-left-item-bottom">{{item.name}}：{{item.ratio}}</div>
						</div>
					</div>
					<div class="info-content-left-info">
						<template v-for="(item, index) in previewInfo.questionKnowledge">
							<div :key="index" class="info-content-left-info-left flex-midden">
								<div class="title-one">{{item.name}}</div>
								<div class="title-one">{{item.total}}道题({{item.ratio}})</div>
							</div>
							<template v-for="(value, key) in item.children">
								<div :key="key" class="info-content-left-info-left flex-midden">
									<div class="title-two">{{value.name}}</div>
									<div class="title-two">{{value.total}}道题({{value.ratio}})</div>
								</div>
								<template v-for="(v, k) in value.children">
									<div :key="k" class="info-content-left-info-left flex-midden">
										<div class="title-three">{{v.name}}</div>
										<div class="title-three">{{v.total}}道题({{v.ratio}})</div>
									</div>
								</template>
							</template>
						</template>
					</div>
					
					<div class="info-content-left-button flex-midden flex-center">
						<div @click="closeSeePager">返回</div>
						<div @click="download">试卷下载</div>
					</div>
				</div>
				
				<div class="info-content-right">
					<div class="info-content-right-hr" v-for="item in previewInfo.questionList" :key="item.id">
						<img style="width: 100%;height: auto;" :src="item.imageUrl"/>
						<div class="info-content-right-score">分值 {{item.score}}分</div>
						<div class="info-content-right-answer flex-start">题目答案<span v-if="item.questionType == 1">：{{item.answer}}</span></div>
						<img style="width: 100%;height: auto;" v-if="item.questionType != 1" :src="item.answerUrl"/>
						<div style="height: 20px;"></div>
					</div>
				</div>
			</div>
		</div>
		<showNum ref="showNumRef"></showNum>
	</div>
</template>

<script>
	import {
		subjectsList,
		examoffices,
		years,
		examseasons,
		knowledgepints,
		difficulties,
		paperTypes,
		questionType
	} from "@/api/config"
	import {
		createpaper,queryPaperNum,
		queryPaperQuestionList,
		deletePaperQuestion,
		paperPreviewDownload,
		queryPaperQuestionList2,
		saveNewPaper,
		updatePaperQuestion,
		addPaperQuestionList,
		getKnowledgeList,
		queryPaperPreviewd,
		getPaperById,
		addImageIndex
	} from "@/api/index"
	import notice from "@/components/notice"
	import downPdf from "@/components/downPdf"
	import showQuestion from "@/components/showQuestion"
	import showPassword from "@/components/showPassword"
	import updateQuestion from "@/components/updateQuestion"
	import showNum from "@/components/showNum"
	import error from "@/components/error"
	export default {
		components:{
			notice,
			downPdf,
			showQuestion,
			updateQuestion,
			error,
			showPassword,
			showNum
		},
		data(){
			return {
				subject:[],
				examname:[],
				yearList:[],
				examseasonList:[],
				oneKnowLed:[],//第一级知识
				difficultiesList:[],//难度列表
				twoKnowLed:[],
				threeKnowLed:[],
				paperTypeList:[],
				questionTypeList:[],//题目类别
				form:{
					exam_offices:[],//考试局
					subjects:[],//科目
					years:[],
					exam_seasons:[],
					min_score:"",
					max_score:"",
					question_num:"",
					paper_num:"",
					paper_score:"",
					single_question_num:"",//单选题数量
					single_answer_num:"",//单选答案数量
					multiple_question_num:"",//多选题数量
					multiple_answer_num:"",//多选题答案数量
					written_question_num:"",//笔试题数量
					examination:1,//判卷类型 1.自己判卷，2.平台分配判卷
					participants:1,//指定作答 1.指定，2.不指定
				},
				type:1,
				num:0,
				step:1,//当前进行的步骤
				paper_id:0,//试卷id
				setting:{
					name:"",//	否	string	试卷名称
					questionType:"",//	否	int	题目类型
					paperType:"",//否	int	试卷类型
					password:"",//否	string	试卷密码
				},
				questionList:[],
				twoData:{//二次检索适用的条件
					examname:[],//考试局
					subject:[],//科目
					yearList:[],//考年
					questionTypeList:[],//题目类别
					examseasonList:[],//考季
					difficultiesList:[],//难度列表
					knowLed:[],//知识点
					one_knowLed:[],
					two_knowLed:[],
					three_knowLed:[]
				},
				search:{//二次获取数据的内容
					offset: 1, 
					limit: 18, 
					knowledgeIds1: [], 
					knowledgeIds2: [], 
					knowledgeIds3: [], 
					difficulty: [], 
					questionType: "", 
					subjects: [], 
					years: [], 
					exam_offices: [], 
					min_score: "", 
					max_score: "", 
					exam_seasons: [],
					knowled:[]
				},
				showSearch:false,
				leftResult:{
					list:[],
					isLoad:true
				},
				//选择左侧加入试卷的相关参数
				selectIds:[],
				selectInfo:[],
				showLeft:true,//是否展开左侧
				totalscore:0,
				previewInfo:{},//预览的数据信息
				queryInfo:{},
				showtwoSearch:false,
				edit:false,
				mutipleNum:0,//笔试题数量
				singleNum:0,//选择题数量
			}
		},
		created(){
			//判断是否有参数
			this.paper_id = this.$route.query.id || 0;
			if(this.paper_id > 0){
				this.edit = true;
				this.showLeft = false;
				this.showtwoSearch = false;
				this.getTestInfo();
				this.getTopSearch();
				getPaperById({id:this.paper_id}).then(res=>{
					if(res.code == 0){
						this.setting = {
							name:res.data.name,//	否	string	试卷名称
							questionType:res.data.questionType,//	否	int	题目类型
							paperType:res.data.paperType,//否	int	试卷类型
							password:res.data.password,//否	string	试卷密码
						};
					}else{
						this.$message.error(res.msg);
						this.$router.go(-1);
					}
				})
			}else{
				this.getConfig();
			}
		},
		methods:{
			changeLeftInfo(){
				this.search.offset = 1;
				this.leftResult.isLoad = true;
				this.getSearchList();
				this.getTestInfo();
			},
			getConfig(){
				//科目
				subjectsList().then(res=>{this.subject = res.data;})
				// 考试局
				examoffices().then(res=>{this.examname = res.data;})
				// 年份
				years().then(res=>{this.yearList = res.data;})
				// 考季
				examseasons().then(res=>{this.examseasonList = res.data;})
				//获取试卷类型
				paperTypes().then(res=>{this.paperTypeList = res.data;})
				//获取题目类别
				questionType().then(res=>{this.questionTypeList = res.data;})
				this.getOneKonwled();
				//难度
				difficulties().then(res=>{
					res.data.map(item=>{
						item.checked = false;
						item.num = "";
					});
					this.difficultiesList = res.data;
				})
			},
			getvalue(list){
				let name = [];
				list.map(item=>{
					if(item.checked == true){
						name.push(item.name);
					}
				})
				return name.join(",");
			},
			getOneKonwled(){
				//一级的知识点
				knowledgepints({level:1,parent_id:0,subjectId:this.form.subjects.join(",")}).then(res=>{
					res.data.map(item=>{
						item.checked = false;
						item.num = "";
					});
					this.oneKnowLed = res.data;
					this.twoKnowLed = [];
					this.threeKnowLed = [];
				})
			},
			//获取第二级知识列表
			getTwoKonwled(id){
				let index = this.twoKnowLed.findIndex(item=>{return item.value == id});
				if(index>-1){
					this.twoKnowLed[index].list.map(value=>{
						let threeindex = this.threeKnowLed.findIndex(item=>{return item.value == value.id});
						if(threeindex>-1){
							this.threeKnowLed.splice(threeindex,1);
						}
					})
					this.twoKnowLed.splice(index,1);
				}else{
					knowledgepints({level:2,parent_id:id,subjectId:this.form.subjects.join(",")}).then(res=>{
						if(res.data.length > 0){
							res.data.map(item=>{
								item.checked = false;
								item.num = "";
							});
							this.twoKnowLed.push({
								value:id,
								list:res.data
							});
						}
					})
				}
				
			},
			//获取三级知识点
			getThreeKonwled(id){
				let index = this.threeKnowLed.findIndex(item=>{return item.value == id});
				if(index>-1){
					this.threeKnowLed.splice(index,1);
				}else{
					knowledgepints({level:3,parent_id:id,subjectId:this.form.subjects.join(",")}).then(res=>{
						if(res.data.length > 0){
							res.data.map(item=>{
								item.checked = false;
								item.num = "";
							});
							this.threeKnowLed.push({
								value:id,
								list:res.data
							});
						}
					})
				}
				
			},
			checkField(){
				//提交一级知识数据
				let knowledge_points1 = [];
				let num = 0;
				this.oneKnowLed.map(item=>{
					if(item.checked == true){
						knowledge_points1.push({key:item.id,value:Number(item.num),parentId:0});
						num += Number(item.num);
					}
				})
				if(num != 100 && knowledge_points1.length != 0){
					return {status:false,msg:"知识点一级的权重和不满足100"};
				}
				//提交二级知识数据
				let knowledge_points2 = [];
				let result = true;
				this.twoKnowLed.map(value=>{
					num = 0;
					value.list.map(item=>{
						if(item.checked == true){
							knowledge_points2.push({key:item.id,value:Number(item.num),parentId:item.pid});
							num += Number(item.num);
						}
					})
					if(num != 100){
						result = false;
					}
				})
				
				if(!result && knowledge_points2.length != 0){
					return {status:false,msg:"知识点二级的权重和不满足100"};
				}
				//提交三级知识数据
				let knowledge_points3 = [];
				result = true;
				this.threeKnowLed.map(value=>{
					num = 0;
					value.list.map(item=>{
						if(item.checked == true){
							knowledge_points3.push({key:item.id,value:Number(item.num),parentId:item.pid});
							num += Number(item.num);
						}
					})
					if(num != 100){
						result = false;
					}
				})
				
				
				if(!result && knowledge_points3.length != 0){
					return {status:false,msg:"知识点三级的权重和不满足100"};
				}
				//提交难度数据
				let difficulty = [];
				num = 0;
				this.difficultiesList.map(item=>{
					if(item.checked == true){
						difficulty.push({key:item.id,value:Number(item.num)});
						num += Number(item.num);
					}
				})
				if(num != 100 && difficulty.length != 0){
					return {status:false,msg:"知识点一级的权重和不满足100"};
				}
				
				return {status:true,msg:"",data:{knowledge_points1:knowledge_points1,knowledge_points2:knowledge_points2,knowledge_points3:knowledge_points3,difficulty:difficulty}};
			},
			//根据当前条件，检索题目的个数
			selectQuestion(){
				let result = this.checkField();
				if(!result.status){
					return this.$message.error(result.msg);
				}
				queryPaperNum({
					exam_offices:this.form.exam_offices,
					subjects:this.form.subjects,
					years:this.form.years,
					exam_seasons:this.form.exam_seasons,
					min_score:this.form.min_score,
					max_score:this.form.max_score,
					question_num:this.form.question_num,
					knowledge_points1:result.data.knowledge_points1,
					knowledge_points2:result.data.knowledge_points2,
					knowledge_points3:result.data.knowledge_points3,
					difficulty:result.data.difficulty
				}).then(res=>{
					if(res.code == 0){
						if(res.data.num == 0){
							this.$message({
							  message: '暂无符合要求的题目',
							  type:"success",
							  offset: 400
							});
						}else{
							this.$message({
							  message: '检查完成，请在下方查看',
							  type:"success",
							  offset: 400
							});
						}
						this.num = res.data.num;
					}else{
						this.$message.error(res.msg);
					}
				})
			},
			//重置，还原自定义组卷的内容
			reset(){
				this.form.single_question_num = "";
				this.form.single_answer_num = "";
				this.form.multiple_question_num = "";
				this.form.multiple_answer_num = "";
				this.form.written_question_num = "";
				this.form.examination = "";
				this.form.participants = "";
				this.form.paper_num = "";
				this.form.paper_score = "";
			},
			//创建真题试卷
			submit(){
				if(this.num == 0){
					return this.$message.error("请先检索题目个数");
				}
				if(this.type == 1 && Number(this.form.paper_num) < 1){
					return this.$message.error("请输入考题数量");
				}
				if(this.type == 1 && Number(this.form.paper_num) > 100){
					return this.$message.error("考题数量不能超过100个");
				}
				if(this.type == 2 && Number(this.form.paper_score) < 1){
					return this.$message.error("请输入考卷分数");
				}
				if(Number(this.form.multiple_question_num) > 0 && Number(this.form.multiple_answer_num) <1){
					return this.$message.error("请输入多选答案数量");
				}
				if(Number(this.form.single_question_num) > 0 && Number(this.form.single_answer_num) <1){
					return this.$message.error("请输入单选答案数量");
				}
				if(!this.form.single_question_num && !this.form.multiple_question_num && !this.form.written_question_num){
					return this.$message.error("单选题、多选题和简答题至少有一个填写数量");
				}
				if(this.type == 1 && Number(this.form.paper_num)!=(Number(this.form.written_question_num) + Number(this.form.multiple_question_num) + Number(this.form.single_question_num))){
					return this.$message.error("题目总数和单选题、多选题和简答题的总数不一致");
				}
				let result = this.checkField();
				if(!result.status){
					return this.$message.error(result.msg);
				}
				
				const loading = this.$loading({
				  lock: true,
				  text: '正在创建中',
				  spinner: 'el-icon-loading',
				  background: 'rgba(0, 0, 0, 0.7)'
				});
				createpaper({
					query:{
						exam_offices:this.form.exam_offices,
						subjects:this.form.subjects,
						years:this.form.years,
						exam_seasons:this.form.exam_seasons,
						min_score:this.form.min_score,
						max_score:this.form.max_score,
						question_num:this.form.question_num,
						knowledge_points1:result.data.knowledge_points1,
						knowledge_points2:result.data.knowledge_points2,
						knowledge_points3:result.data.knowledge_points3,
						difficulty:result.data.difficulty
					},
					paper:{
						paperNum:this.type==1?this.form.paper_num:0,
						paperScore:this.type==2?this.form.paper_score:0,
						singleNum:Number(this.form.single_question_num),
						singleAnswerNum:this.form.single_answer_num,
						mutipleNum:Number(this.form.multiple_question_num),
						multipleAnswerNum:this.form.multiple_answer_num,
						writtenNum:Number(this.form.written_question_num),
						examination:this.form.examination,
						participant:this.form.participants,
					}
				}).then(res=>{
					if(res.code == 0){
						this.paper_id = res.data.paper_id;
						this.getTestInfo();
						this.getTopSearch();
						loading.close();
						// 增加数目弹框统计
						// num检索题目的数量
						this.$refs.showNumRef.init(this.num,this.paper_id);
						
					}else{
						loading.close();
						this.$message.error(res.msg);
					}
				})
			},
			//生成二次检索的条件数组
			setTwoData(){
				//难度
				this.twoData.difficultiesList = [];
				
				//难度
				difficulties().then(res=>{
					res.data.map(value=>{
						if(this.queryInfo.query.difficulty.indexOf(value.id) > -1){
							this.twoData.difficultiesList.push(value);
						}
					})
					if(this.twoData.difficultiesList.length == 0){
						this.twoData.difficultiesList = res.data;
					}
					
				})
				//考试局
				this.twoData.examname = [];
				// 考试局
				examoffices().then(res=>{
					if(this.queryInfo.query.exam_offices.length > 0){
						res.data.map(item=>{
							if(this.queryInfo.query.exam_offices.indexOf(item.id) > -1){
								this.twoData.examname.push(item);
							}
						})
					}else{
						this.twoData.examname = res.data;
					}
				})
				//科目
				this.twoData.subject = [];
				subjectsList().then(res=>{
					if(this.queryInfo.query.subjects.length > 0){
						res.data.map(item=>{
							if(this.queryInfo.query.subjects.indexOf(item.id) > -1){
								this.twoData.subject.push(item);
							}
						})
					}else{
						this.twoData.subject = res.data;
					}
				})
				//考年
				this.twoData.yearList = [];
				years().then(res=>{
					if(this.queryInfo.query.years.length > 0){
						res.data.map(item=>{
							if(this.queryInfo.query.years.indexOf(item.id) > -1){
								this.twoData.yearList.push(item);
							}
						})
					}else{
						this.twoData.yearList = res.data;
					}
				})
				
				//考季
				this.twoData.examseasonList = [];
				
				examseasons().then(res=>{
					if(this.queryInfo.query.exam_seasons.length > 0){
						res.data.map(item=>{
							if(this.queryInfo.query.exam_seasons.indexOf(item.id) > -1){
								this.twoData.examseasonList.push(item);
							}
						})
					}else{
						this.twoData.examseasonList = res.data;
					}
				})
				
				//题目类别
				questionType().then(res=>{this.twoData.questionTypeList = res.data;})
				
				//获取试卷类型
				paperTypes().then(res=>{this.paperTypeList = res.data;})
				
				//一级的知识点
				this.twoData.knowLed = [];
				this.twoData.one_knowLed = [];
				this.twoData.two_knowLed = [];
				this.twoData.three_knowLed = [];
				knowledgepints({level:1,parent_id:0,subjectId:this.queryInfo.query.subjects.join(",")}).then(res=>{
					let knowledgeIds1 = [];
					this.queryInfo.query.knowledge_points1.map(item=>{
						knowledgeIds1.push(item.key);
					})
					let knowledgeIds2 = [];
					this.queryInfo.query.knowledge_points2.map(item=>{
						knowledgeIds2.push(item.key);
					})
					let knowledgeIds3 = [];
					this.queryInfo.query.knowledge_points3.map(item=>{
						knowledgeIds3.push(item.key);
					})
					res.data.map(item=>{
						if(knowledgeIds1.indexOf(item.id) > -1){
							this.twoData.knowLed.push(item);
						}
					})
					if(this.twoData.knowLed.length == 0){
						this.twoData.knowLed = res.data;
					}
					let ids = [];
					this.twoData.knowLed.map(item=>{
						ids.push(item.id);
					})
					if(ids.length > 0){
						getKnowledgeList({ids:ids.join(","),subjectId:this.queryInfo.query.subjects.join(",")}).then(res=>{
							if(res.code == 0){
								this.twoData.knowLed.map(item=>{
									this.twoData.one_knowLed.push(item);
									item.children = [];
									res.data.map(value=>{
										if(value.id == item.id){
											//二级数据
											value.children.map(v=>{
												//检索三级满足条件的
												v.haschildren = [];
												v.children.map(v1=>{
													if(knowledgeIds3.indexOf(v1.id) > -1){
														this.twoData.three_knowLed.push(v1);
														v.haschildren.push(v1);
													}
												})
												if(v.haschildren.length == 0){
													this.twoData.three_knowLed = [...this.twoData.three_knowLed,...v.children];
													v.haschildren = v.children;
												}
												if(knowledgeIds2.indexOf(v.id) > -1){
													this.twoData.two_knowLed.push(v);
													item.children.push({id:v.id,name:v.name,level:v.level,children:v.haschildren});
												}
											})
											if(item.children.length == 0){
												value.children.map(v=>{
													this.twoData.two_knowLed.push(v);
													item.children.push({id:v.id,name:v.name,level:v.level,children:v.haschildren});
												});
											}
										}
									})
								})
								this.showtwoSearch = true;
							}
						})
					}
				})
			},
			//获取试卷详情
			// 获取试卷详情
getTestInfo() {
    queryPaperQuestionList({ id: this.paper_id }).then(res => {
        if (res.code == 0) {
            this.questionList = res.data.data;
            this.totalscore = res.data.score;
            this.mutipleNum = res.data.mutipleNum;
            this.singleNum = res.data.singleNum;
            this.queryInfo = JSON.parse(res.data.query_string);
            this.setTwoData();

            const that = this; // 保存当前 Vue 实例的上下文
            const loading = this.$loading({
                lock: true,
                text: '正在修改题号',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            // 创建包含所有异步操作的 Promise 数组
            const promises = this.questionList.map(item => {
                return addImageIndex({
                    questionid: item.questionId,
                    index: item.sort,
                    left: item.left_v,
                    top: item.top_v,
                    size: item.size_v,
                    imageUrl: item.imageUrl
                }).then(addRes => {
                    if (addRes.code == 0) {
                        item.imageUrl = addRes.url;
                        // 返回 updatePaperQuestion 的 Promise 以链式跟踪
                        return updatePaperQuestion({
                            id: item.id,
                            newPaperId: that.paper_id,
                            questionId: item.questionId,
                            imageUrl: item.imageUrl,
                            answerUrl: "",
                            sort: item.sort,
                            score: item.score,
                            left_v: item.left_v,
                            top_v: item.top_v,
                            size_v: item.size_v
                        }).then(updateRes => {
                            if (updateRes.code != 0) {
                                that.$message.error(updateRes.msg);
                            }
                        });
                    } else {
                        that.$message.error(addRes.msg);
                        return Promise.resolve(); // 保持 Promise 链继续
                    }
                }).catch(err => { // 捕获可能的异常
                    that.$message.error(err.msg || '处理题号时发生错误');
                    return Promise.resolve();
                });
            });

            // 所有操作完成后关闭 loading 并更新步骤
            Promise.all(promises)
                .finally(() => {
                    loading.close();
                    that.step = 2; // 确保在最后更新步骤
                });
        } else {
            this.getConfig();
            this.$message.error(res.msg);
        }
    });
},
			//删除试卷
			deleteimage(id){
				this.$refs.noticeRef.init("温馨提示","是否要删除该题","确认",{id:id});
			},
			//弹框确认的结果
			noticeSubmit(data){
				deletePaperQuestion({
					paper_id:this.paper_id,
					id:data.id
				}).then(res=>{
					if(res.code == 0){
						this.getTestInfo();
						this.getTopSearch();
						this.$message.success(res.msg);
					}else{
						this.$message.error(res.msg);
					}
				})
			},
			//下载试卷
			download(){
				this.$refs.downPdfRef.init({id:this.paper_id});
				
			},
			downloadSubmit(e){
				paperPreviewDownload({
					id:e.id,
					type:e.type,
					name:this.setting.name,
					questionType:this.setting.questionType,
					paperType:this.setting.paperType,
					paperScore:this.totalscore,
					questionList:this.questionList
				}).then(res=>{
					const url = window.URL.createObjectURL(new Blob([res]))
					const link = document.createElement("a")
					link.href = url
					if(e.type == 1){
						link.setAttribute("download",'教师版.pdf')//导出的格式一定要和接口返回的文件一致
					}else{
						link.setAttribute("download",'学生版.pdf')//导出的格式一定要和接口返回的文件一致
					}
					
					document.body.appendChild(link)
					link.click()
					window.URL.revokeObjectURL(url)
					document.body.removeChild(link)
				})
			},
			twoDataSearch(){
				this.search.knowledgeIds1 = [];
				this.search.knowledgeIds2 = [];
				this.search.knowledgeIds3 = [];
				this.search.knowled.map(item=>{
					if(item.length == 1){
						this.search.knowledgeIds1.push(item[0]);
					}else if(item.length == 2){
						this.search.knowledgeIds2.push(item[1]);
					}else if(item.length == 3){
						this.search.knowledgeIds3.push(item[2]);
					}
				})
				this.getTopSearch();
			},
			twoDataReset(){
				this.search = {
					offset: 1,
					limit: 18, 
					knowledgeIds1: [], 
					knowledgeIds2: [], 
					knowledgeIds3: [], 
					difficulty: [], 
					questionType: "", 
					subjects: [], 
					years: [], 
					exam_offices: [], 
					min_score: "", 
					max_score: "", 
					exam_seasons: [],
					knowled:[]
				};
				this.getTopSearch();
			},
			getTopSearch(){
				this.search.offset = 1;
				this.leftResult.list = [];
				this.leftResult.isLoad = true;
				this.getSearchList();
			},
			//获取左侧可检索到饿题目列表
			getSearchList(){
				if(this.leftResult.isLoad == false){
					return false;
				}
				this.leftResult.isLoad = false;
				queryPaperQuestionList2({
					paper_id:this.paper_id,
					...this.search
				}).then(res=>{
					if(res.code == 0){
						if(res.data.data){
							if(this.search.offset == 1){
								this.leftResult.list = res.data.data;
							}else{
								this.leftResult.list = [...this.leftResult.list,...res.data.data];
							}
							if(res.data.data.length >= this.search.limit){
								this.leftResult.isLoad = true;
							}
						}
					}else{
						this.$message.error(res.msg);
					}
				})
			},
			//获取下一页的数据
			getNextData(){
				if(this.leftResult.isLoad){
					this.search.offset++;
					this.getSearchList();
				}
			},
			//操作左侧选项内容
			actionSelect(item){
				let index = this.selectIds.indexOf(item.id);
				if(index == -1){
					this.selectInfo.push(item);
					this.selectIds.push(item.id);
				}else{
					this.selectInfo.splice(index, 1);
					this.selectIds.splice(index, 1);
				}
			},
			//加入左侧题目
			addLeftAll(){
				this.selectInfo = [];
				this.selectIds = [];
				this.leftResult.list.map(item=>{
					this.selectInfo.push(item);
					this.selectIds.push(item.id);
				})
			},
			//清空左侧选择
			clearLeftAll(){
				this.selectInfo = [];
				this.selectIds = [];
			},
			//查看左侧的题目
			seeQuestion(index){
				let num = this.questionList.length;
				let sort = 0;
				if(num > 0){
					sort = this.questionList[num-1].sort;
				}
				this.$refs.showQuestionRef.init(index, this.leftResult.list,this.paper_id,sort);
			},
			//批量增加试卷
			addMoreQuestion(){
				if(this.selectIds == 0){
					return this.$message.error("请选择题目");
				}
				let data = [];
				let sort = 1;
				if(this.questionList.length > 0){
					sort = this.questionList[this.questionList.length-1].sort;
				}
				this.selectInfo.map(item=>{
					sort = Number(sort)+1;
					data.push({
						newPaperId: this.paper_id,
						questionId: item.id, 
						questionTitle: item.title, 
						imageUrl: item.imageUrl, 
						answerUrl: item.answerUrl, 
						sort: sort, 
						score: item.score, 
						questionType: item.questionType
					})
				})
				
				const loading = this.$loading({
				  lock: true,
				  text: '正在加入中',
				  spinner: 'el-icon-loading',
				  background: 'rgba(0, 0, 0, 0.7)'
				});
				addPaperQuestionList(data).then(res=>{
					if(res.code == 0){
						loading.close();
						this.selectInfo = [];
						this.selectIds = [];
						this.getTestInfo();
						this.search.offset = 1;
						this.leftResult.isLoad = true;
						this.getSearchList();
						this.$message.success("加入成功");
					}else{
						loading.close();
						this.$message.error(res.msg);
					}
				})
			},
			//操作切题
			changeQuestion(item){
				this.$refs.updateQuestionRef.init({...item,paper_id:this.paper_id});
			},
			//展示错误题目类型
			showError(id){
				this.$refs.errorRef.init(id);
			},
			//修改题号和分值
			updateInfo(item,type){
				let imageUrl=''
				if(type==1)
				{
					addImageIndex({
							questionid:item.questionId,
							index:item.sort,
							left:item.left_v,
							top:item.top_v,
							size:item.size_v,
							imageUrl:item.imageUrl
						}).then(res=>{
							if(res.code==0){
								item.imageUrl = res.url;

								// this.$set(item, 'imageUrl', newUrl);

								imageUrl = item.imageUrl;
								updatePaperQuestion({
									id: item.id,
									newPaperId: this.paper_id,
									questionId: item.questionId,
									imageUrl: imageUrl,
									answerUrl: "",
									sort: item.sort,
									score: item.score,
									left_v:item.left_v,
									top_v:item.top_v,
									size_v:item.size_v
								}).then(res => {
									if (res.code == 0) {
										// this.getTestInfo();
									} else {
										this.$message.error(res.msg);
									}
								})
							}
						}
					)
				}
				else{
					updatePaperQuestion({
						id: item.id,
						newPaperId: this.paper_id,
						questionId: item.questionId,
						imageUrl: imageUrl,
						answerUrl: "",
						sort: item.sort,
						score: item.score
					}).then(res => {
						if (res.code == 0) {
							this.getTestInfo();
						} else {
							this.$message.error(res.msg);
						}
					})
				}
				
			},
			//查看右侧的答案
			seepassword(index){
				this.$refs.showPasswordRef.init(index, this.questionList,this.paper_id);
			},
			//预览试卷
			seePager(){
				queryPaperPreviewd({
				id:this.paper_id,
				name:this.setting.name,
				questionType:this.setting.questionType,
				paperType:this.setting.paperType,
				paperScore:this.totalscore,
				questionList:this.questionList,
				}).then(res=>{
					if (res.code == 0) {
						this.step = 3;
						this.previewInfo = res.data;
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			//关闭试卷预览
			closeSeePager(){
				this.step = 2;
			},
			toIndex(){
				this.$router.push("/");
			},
			topager(){
				this.$router.push("/pager");
			},
			//保存试卷
			submitPage(){
				if(this.setting.name == ""){
					return this.$message.error("请输入自组试卷名称");
				}
				if(this.setting.questionType == ""){
					return this.$message.error("请选择题目类型");
				}
				if(this.setting.paperType == ""){
					return this.$message.error("请选择试卷类型");
				}
				const loading = this.$loading({
				  lock: true,
				  text: '正在创建中',
				  spinner: 'el-icon-loading',
				  background: 'rgba(0, 0, 0, 0.7)'
				});
				saveNewPaper({
					id:this.paper_id,
					user_id:Number(this.$cookies.get('teacheruserId')),
					name:this.setting.name,
					participant:this.queryInfo.paper.participant,
					question_type:this.setting.questionType,
					paper_type:this.setting.paperType,
					single_num:0,
					mutiple_num:0,
					written_num:0,
					password:this.setting.password,
					exam_offices:this.queryInfo.query.exam_offices,
					subjects:this.queryInfo.query.subjects,
					years:this.queryInfo.query.years,
					exam_seasons:this.queryInfo.query.exam_seasons,
					difficulty:this.queryInfo.query.difficulty,
					knowledge_points1:this.queryInfo.query.knowledge_points1,
					knowledge_points2:this.queryInfo.query.knowledge_points2,
					knowledge_points3:this.queryInfo.query.knowledge_points3,
					examination:this.queryInfo.paper.examination,
					score:this.totalscore
				}).then(res=>{
					if(res.code == 0){
						loading.close();
						this.$router.replace("/");
					}else{
						loading.close();
						this.$message.error(res.msg);
					}
				})
			},
			findName(){
				let info = this.paperTypeList.find(item=>{return item.id == this.setting.paperType});
				
				return info?info.name:'';
			}
			
		}
	}
</script>

<style lang="scss" scoped>
	@import "../assets/create.scss";
	@import "../assets/info.scss";
	.getMoreInfo{
		text-align: center;
		font-size: 14px;
		color: #FF8D1F;
		cursor: pointer;
	}
	.create-result-title {
		display: flex;
		gap: 12px; /* 控制子元素间距 */
		padding: 8px;
	}
	.create-result-title-input {
		flex: 0 0 auto;
	}
</style>