<template>
	<div class="report">
		<img class="report-close" @click="tolast" src="@/assets/images/close.png"/>
		<div class="report-title">答题报告</div>
		<div id="report-info">
			<div class="report-list flex-start flex-center flex-wrap">
				<div class="report-item" v-for="(item, index) in list" :key="index" v-if="item.level==1">
					<div class="report-item-title">{{item.name}}</div>
					<div class="report-item-info" :id="'main'+index"></div>
				</div>
			</div>
			<div class="report-list flex-start flex-center flex-wrap">
				<div class="report-item" v-for="(item, index) in list" :key="index" v-if="item.level==2">
					<div class="report-item-title">{{item.name}}</div>
					<div class="report-item-info" :id="'main'+index"></div>
				</div>
			</div>
			<div class="report-list flex-start flex-center flex-wrap">
				<div class="report-item" v-for="(item, index) in list" :key="index" v-if="item.level==3">
					<div class="report-item-title">{{item.name}}</div>
					<div class="report-item-info" :id="'main'+index"></div>
				</div>
			</div>
		</div>
		
		<div style="height: 100px;"></div>
		<el-tooltip placement="top" effect="light" :visible-arrow="false">
			<div slot="content">
				<div class="show-button">
					<div @click="downloadReport">表单报告</div>
					<div @click="downloadQRCode">绘图报告</div>
				</div>
			</div>
			<div class="bottom">
				<div>下载报告</div>
			</div>
		</el-tooltip>
		
	</div>
</template>

<script>
	
	import {getPaperReport,downloadReport} from "@/api/index"
	import * as echarts from 'echarts';
	import html2canvas from 'html2canvas';
	export default {
		data(){
			return {
				id:0,
				list:[],
				answerId:0
			}
		},
		mounted() {
			this.id = this.$route.query.id || 0;
			this.answerId = this.$route.query.answerId || 0;
			
			getPaperReport({
				paperId:this.id,
				answerId:this.answerId,
				isTeacher:1
			}).then(res=>{
				if(res.code == 0){
					this.list = res.data;
					setTimeout(()=>{
						res.data.map((item,index)=>{
							var chartDom = document.getElementById('main'+index);
							var myChart = echarts.init(chartDom);
							var option;
							let x = [];
							let y = [];
							item.y.map(value=>{
								x.push(value.name);
								y.push(value.divide);
							})
							option = {
							  xAxis: {
							    type: 'category',
							    data: x,
								axisLine: {
								    symbol: ['none', 'arrow'],
								    symbolSize: 10
								}
							  },
							  yAxis: {
							    type: 'value'
							  },
							  series: [
								  {
									data: y,
									type: 'bar',
									barWidth: 20,
									itemStyle: {
									  normal: {
										//这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
										barBorderRadius:[10, 10, 0, 0],
										color:function (params) {
										  // 根据params的
										  const colorsMap = [
										  '#FF8D1F',
										  '#66EEEC',
										  '#1F9DFF',
										  '#FF8E84','#FF8D1F',
										  '#66EEEC',
										  '#1F9DFF',
										  '#FF8E84','#FF8D1F',
										  '#66EEEC',
										  '#1F9DFF',
										  '#FF8E84'
										  ]
										  //返回对应的颜色
										  return colorsMap[params.dataIndex]
										}
									  }
									},
									label: {
									  show: true, // 显示标签
									  position: 'top', // 标签位置顶部
									  formatter: '{c}%' // 标签格式器，{c} 代表数据值
									}
								  }
								]
							};
							
							option && myChart.setOption(option);
						})
					},500);
					
				}else{
					this.$router.replace("/");
				}
			})
		},
		methods:{
			tolast(){
				this.$router.go(-1); 
			},
			downloadReport(){
				downloadReport({
					paperId:this.id,
					answerId:this.answerId
				}).then(res=>{
					const url = window.URL.createObjectURL(new Blob([res]))
					const link = document.createElement("a")
					link.href = url
					link.setAttribute("download",'报告.xlsx')//导出的格式一定要和接口返回的文件一致
					document.body.appendChild(link)
					link.click()
					window.URL.revokeObjectURL(url)
					document.body.removeChild(link)
				})
			},
			downloadQRCode() {
				const targetElement = document.querySelector('#report-info')
			    // 将目标元素转为图片
				html2canvas(targetElement)
					.then(canvas => {
			            // 创建下载链接
						const link = document.createElement('a')
						link.download = `报告.jpg`
						link.href = canvas.toDataURL()
			            // 点击下载链接进行下载
						link.click()
					})
			},
		}
	}
</script>


<style lang="scss" scoped>
	@import "../assets/report.scss";
	
</style>